import Button from '../atoms/Button';
import OrderButton from '../OrderButton';
import './Contacts.scss';

export const Contacts = () => {
  return (
    <div className='contacts-block'>
      <div itemScope itemType='https://schema.org/Organization'>
        <div className='contacts-block-header-block'>
          <h3 className='contacts-block-header' itemProp='name'>
            Контакты
          </h3>
        </div>
        <div className='contacts-block-items'>
          <div className='contacts-block-item'>
            <div className='contacts-block-item-caption'>Контактный телефон</div>
            <div className='contacts-block-item-text'>
              <a href='tel:+79857848490'>+7 (495) 177-98-97</a>
            </div>
          </div>
          <div className='contacts-block-item'>
            <div className='contacts-block-item-caption'>E-mail</div>
            <div className='contacts-block-item-text'>
              <a href='mailto:i@ffixmyapple.ru'>i@ffixmyapple.ru</a>
            </div>
          </div>
          <div
            className='contacts-block-item'
            itemProp='address'
            itemScope
            itemType='https://schema.org/PostalAddress'
          >
            <div className='contacts-block-item-caption'>Адрес</div>
            <div className='contacts-block-item-text'>
              000000, <span itemProp='addressLocality'>Москва, м. Кутузовская</span>{' '}
              <span itemProp='streetAddress'>Кутузовский проспект, д. 36, стр 41</span>
            </div>
          </div>
          <div className='contacts-block-item'>
            <div className='contacts-block-item-caption'>Время работы</div>
            <div className='contacts-block-item-text'>
              Пн-Пт с 10:00 до 19:00
              <br />
              Сб, Вс выходной
            </div>
          </div>
          <div className='contacts-block-button'>
            <OrderButton type='footer-call' orderName='Кнопка на карте в подвале сайта'>
              <Button>Связаться с нами</Button>
            </OrderButton>
          </div>
        </div>
      </div>
    </div>
  );
};
