import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useBreakpoint from 'use-breakpoint';

import './Home.scss';
import Benefits from '../../components/Benefits';
import DevicesBlock from '../../components/DevicesBlock';
import BrandBlock from '../../components/BrandBlock';
import MainBanner from '../../components/MainBanner';
import HelpBanner from '../../components/HelpBanner';
import TextBanner from '../../components/TextBanner';
import FeedbackBlock from '../../components/FeedbackBlock';
import bannerImg from '../../assets/images/main-banner.png';
import { getAllBrands } from '../../utils/api';
import { load } from '../../redux/slices/brandSlice';
import { Map } from '../../components/Map/Map';
import { BREAKPOINTS } from '../../constants/breakpoints';

export const Home = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const brands = useSelector((state) => state.brands.items);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllBrands().then((data) => {
      dispatch(load(data));
    });
  }, []);

  return (
    <div className='section'>
      <div className='content-section'>
        <MainBanner
          caption='Сервисный центр'
          header='FMA сервис'
          text={`Профессиональный ремонт смартфонов, планшетов, ноутбуков <br /> любой сложности.`}
          img={bannerImg}
        />
      </div>
      <div className='content-section'>
        <Benefits />
      </div>
      <div className='content-section'>
        <DevicesBlock />
      </div>
      <div className='content-section'>
        <BrandBlock items={brands} baseUrlPath='/' />
      </div>
      <div className='content-section'>
        <HelpBanner />
      </div>
      <div className='content-section'>
        <TextBanner />
      </div>
      <div className='content-section'>
        <FeedbackBlock />
      </div>
      {breakpoint !== 'mobile' && <Map />}
    </div>
  );
};
