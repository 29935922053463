import React from 'react';
import { Link } from 'react-router-dom';

import PaymentMethodList from './components/PaymentMethodList';
import { SocialLinks } from '../SocialLinks/SocialLinks';
// import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
// import { ReactComponent as LogoIcon } from '../../assets/old-logo.svg';
import logoOld from '../../assets/images/logo-old.jpeg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/Phone.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/Mail.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/Location.svg';
import gazpromImg from '../../assets/images/gazprom-media.svg';
import sberImg from '../../assets/images/sber.jpg';
import tsumImg from '../../assets/images/tsum.png';

import './Footer.scss';
export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-wrapper'>
        <div className='footer-content'>
          <div className='footer-top-section'>
            <div className='footer-content-column'>
              <div className='row'>
                <a href='/' className='footer-logo-wrapper'>
                  {/* <LogoIcon className='footer-logo' /> */}
                  <img src={logoOld} alt='' />
                  <span className='logo-subtitle'>СЕРВИСНЫЙ ЦЕНТР</span>
                </a>
                <div className='footer-raiting'>
                  <iframe
                    src='https://yandex.ru/sprav/widget/rating-badge/184772866831?type=rating&theme=dark'
                    width='150'
                    height='50'
                    frameBorder='0'
                  ></iframe>
                </div>
              </div>
              <div className='row'>
                <div className='footer-social'>
                  <SocialLinks />
                </div>
                <div className='footer-legal'>
                  <p>ИП 770165541781</p>
                  <p>ОГРН 322774600447489</p>
                </div>
              </div>
            </div>
            <div className='footer-content-column'>
              <div className='footer-navigation'>
                <div className='footer-navigation-column'>
                  <div className='footer-menu'>
                    <div className='footer-menu-header'>Поиск по устройствам</div>
                    <ul className='footer-menu-list'>
                      <li className='footer-menu-item'>
                        <Link to='/phones' className='footer-menu-link'>
                          Телефоны
                        </Link>
                      </li>
                      <li className='footer-menu-item'>
                        <Link to='/laptops' className='footer-menu-link'>
                          Ноутбуки
                        </Link>
                      </li>
                      <li className='footer-menu-item'>
                        <Link to='/tablets' className='footer-menu-link'>
                          Планшеты
                        </Link>
                      </li>
                      <li className='footer-menu-item'>
                        <Link to='/watches' className='footer-menu-link'>
                          Умные часы
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='footer-navigation-column'>
                  <div className='footer-menu'>
                    <div className='footer-menu-header'>Навигация</div>
                    <ul className='footer-menu-list'>
                      <li className='footer-menu-item'>
                        <a href='#' className='footer-menu-link'>
                          О компании
                        </a>
                      </li>
                      <li className='footer-menu-item'>
                        <Link to='/contacts' className='footer-menu-link'>
                          Контакты
                        </Link>
                      </li>
                      <li className='footer-menu-item'>
                        <a href='#' className='footer-menu-link'>
                          Услуги
                        </a>
                      </li>
                      <li className='footer-menu-item'>
                        <a href='#' className='footer-menu-link'>
                          Цены
                        </a>
                      </li>
                      <li className='footer-menu-item'>
                        <Link to='/feedbacks' className='footer-menu-link'>
                          Отзывы
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='footer-content-column'>
              <div className='footer-menu'>
                <div className='footer-menu-header'>Контакты</div>
                <div className='footer-menu-content'>
                  <div className='footer-contacts'>
                    <a href='tel:+1234567890' className='footer-contact'>
                      <PhoneIcon /> +7 (495) 177-98-97
                    </a>
                    <a href='mailto:i@ffixmyapple.ru' className='footer-contact'>
                      <MailIcon /> i@ffixmyapple.ru
                    </a>
                  </div>
                  <div className='footer-address'>
                    <LocationIcon />
                    <div className='footer-address-content'>
                      <p>Москва, м. Кутузовская,</p>
                      <p>Кутузовский пр-т д. 36 стр. 41</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='footer-content-column'>
              <div className='footer-menu'>
                <div className='footer-menu-header'>Наши партнеры</div>
                <div className='footer-menu-content'>
                  <img className='footer-partner-image' src={sberImg} loading='lazy' alt='' />
                  <img className='footer-partner-image' src={gazpromImg} loading='lazy' alt='' />
                  <img className='footer-partner-image' src={tsumImg} loading='lazy' alt='' />
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom-section'>
            <div>
              <p>&copy; {new Date().getFullYear()} FMA Service. Все права защищены.</p>
            </div>
            <div>
              <PaymentMethodList />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
